import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import { CartValidator } from 'components/Cart/validation'
import { OrderingWindow } from 'components/Ordering/OrderingWindow'
import { useSheetToggle } from 'components/Venue/utils/sheetUtils'
import { isToday, parseISO } from 'date-fns'
import {
  GuestVenue,
  OrderingType,
  VenueOrderingWindowsDocument,
} from 'gql/graphql'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from 'urql'

import { getSelectTextFromSheet, useGetNextSheet } from './utils/sheetUtils'
import { OrderingTypeVenueValidator } from './utils/venueUtils'

interface Props {
  venue: Pick<GuestVenue, 'skipTableNumberModal'> & OrderingTypeVenueValidator
  cart?: CartValidator | null
  venueSlug: string
  orderingType: OrderingType
  tablePromptText?: string
}

export const OrderingWindowButton = ({
  venue,
  cart,
  venueSlug,
  orderingType,
  tablePromptText,
}: Props) => {
  const intl = useIntl()
  const sheetToggle = useSheetToggle()

  const [{ data: venueOrderingWindowsData }] = useQuery({
    query: VenueOrderingWindowsDocument,
    variables: { venueSlug, orderingType },
  })

  const firstOrderingWindow =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow?.availableOrderingWindows.find(
      (availableOrderingWindow) => availableOrderingWindow.isPast === false,
    )

  const today = firstOrderingWindow?.orderingWindowStartDate
    ? isToday(parseISO(firstOrderingWindow?.orderingWindowStartDate))
    : false

  const asapSettings =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow
      ?.asapWindowEnabled

  const startDate = cart?.orderingWindowStartDate

  const { nextSheet, addPaths, editPaths } = useGetNextSheet({
    cart,
    asapEnabled: asapSettings,
    venue,
  })

  const handleOnClick = useCallback(() => {
    void sheetToggle({ sheet: nextSheet })
  }, [nextSheet, sheetToggle])

  const tableNumber = cart?.tableNumber

  if (addPaths.length === 0 && editPaths.length === 0) {
    return null
  }

  if (tableNumber && !addPaths.includes('table-number')) {
    return null
  }

  return (
    <Button
      type="button"
      variant="link-primary"
      size="sm"
      onClick={handleOnClick}
      className="gap-0"
      aria-label={intl.formatMessage({
        defaultMessage: 'Change ordering window',
        id: 'yJPkAI',
      })}
      data-testid="change-ordering-window"
      rightIcon={
        <ChevronDownIcon width={20} height={20} className="flex-shrink-0" />
      }
    >
      {startDate && (
        <OrderingWindow
          orderingType={orderingType}
          startDate={startDate}
          endDate={cart?.orderingWindowEndDate}
          className="text-left"
        />
      )}

      {addPaths.length > 0 &&
        !startDate &&
        !asapSettings &&
        getSelectTextFromSheet(nextSheet ?? null, tablePromptText, intl)}

      {!startDate &&
        asapSettings &&
        !today &&
        getSelectTextFromSheet(nextSheet ?? null, tablePromptText, intl)}

      {asapSettings && !startDate && today && (
        <div data-testid="asap-text">
          <FormattedMessage defaultMessage="ASAP" id="K5T4eC" />
        </div>
      )}

      {addPaths.length > 0 &&
        startDate &&
        getSelectTextFromSheet(nextSheet ?? null, tablePromptText, intl)}
    </Button>
  )
}
