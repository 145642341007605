import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { CurrentUserDocument } from 'gql/graphql'
import React from 'react'
import { useQuery } from 'urql'

import { UserGreeting } from './UserGreeting'

const PersonalisedExperience = () => {
  const [{ data: userData, fetching: userDataFetching }] = useQuery({
    query: CurrentUserDocument,
    pause: isServer,
  })

  if (!userData?.currentUser && !userDataFetching) {
    return null
  }
  return (
    <div className="px-4 pt-4">
      <UserGreeting
        fetching={userDataFetching}
        currentUser={userData?.currentUser}
      />
    </div>
  )
}

export default PersonalisedExperience
