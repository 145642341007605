import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import { CartValidator } from 'components/Cart/validation'
import { Address } from 'components/Ordering/Address'
import { useSheetToggle } from 'components/Venue/utils/sheetUtils'
import { venueToOrderingTypeItems } from 'components/Venue/utils/venueUtils'
import { useOrderingTypeContext } from 'contexts/VenueOrderContext'
import { OrderingType, VenueLandingQuery } from 'gql/graphql'
import { useLogger } from 'hooks/useLogger'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  venue: VenueLandingQuery['guestVenue']
  cart?: CartValidator | null
}

export const AddressButton = ({ venue, cart }: Props) => {
  const { logEvent } = useLogger()
  const sheetToggle = useSheetToggle()
  const intl = useIntl()

  const handleOnClick = useCallback(() => {
    logEvent('Clicked on address selector')
    void sheetToggle({
      sheet: 'address',
    })
  }, [logEvent, sheetToggle])

  const { orderingType } = useOrderingTypeContext()
  const { nonMenuCount, items } = venueToOrderingTypeItems(
    venue,
    orderingType,
    intl,
  )

  const hasMultipleOrderingTypes = items.length > 1

  if (nonMenuCount === 0) {
    return null
  }

  if (
    (!hasMultipleOrderingTypes && orderingType === OrderingType.DineIn) ||
    !cart?.currentLocation
  ) {
    return null
  }

  return (
    <Button
      type="button"
      variant="link-primary"
      size="sm"
      onClick={handleOnClick}
      className="gap-0"
      aria-label={intl.formatMessage({
        defaultMessage: 'Change delivery address',
        id: 'LK6WmT',
      })}
      data-testid="change-delivery-address"
      rightIcon={
        <ChevronDownIcon width={20} height={20} className="flex-shrink-0" />
      }
    >
      <Address
        className="max-w-[65vw] text-left"
        location={cart?.currentLocation}
      />{' '}
    </Button>
  )
}
