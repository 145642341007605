import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { ErrorNotification } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import {
  findSettingsByOrderingTypeV2,
  useVenueMenuCategory,
} from 'components/Venue/utils/venueUtils'
import { MenuCategories } from 'components/VenueV2/Categories/MenuCategories'
import { MenuCategoriesLoader } from 'components/VenueV2/Categories/MenuCategoriesLoader'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { CartLandingQuery, VenueLandingQuery } from 'gql/graphql'
import React, { useMemo } from 'react'

import { randomizeCategories } from './utils/categoryUtils'

const SINGLE_COLUMN_THRESHOLD = 4
const LOADER_CATEGORY_COUNT = 6

interface VenueCategoriesProps {
  multiVendorEnabled: boolean
  showCategoryListViewOnLandingPage: boolean
  cart: CartLandingQuery['getCart']
  cartStale: boolean
  cartFetching: boolean
  orderingTypes: NonNullable<VenueLandingQuery['guestVenue']>['orderingTypes']
  debouncedCategorySearch: string
}

export const VenueCategories = ({
  multiVendorEnabled,
  showCategoryListViewOnLandingPage,
  cart,
  cartFetching,
  orderingTypes,
  debouncedCategorySearch,
}: VenueCategoriesProps) => {
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const settings = findSettingsByOrderingTypeV2({
    orderingTypes,
    orderingType,
  })
  const orderingWindowEnabled = settings?.orderingWindow?.enabled ?? false

  const {
    data,
    error,
    fetching: categoriesFetching,
  } = useVenueMenuCategory({
    cart,
    cartFetching,
  })

  const menuCategories = data?.guestMenuCategories

  const filteredMenuCategories = useMemo(() => {
    return randomizeCategories(menuCategories ?? [], multiVendorEnabled)
  }, [menuCategories, multiVendorEnabled])

  const isLoading = isServer || categoriesFetching || cartFetching
  const gridClassNames = 'grid gap-x-3 gap-y-5'

  if (error && !menuCategories) {
    return <ErrorNotification fullWidth error={error} />
  }

  if (isLoading) {
    return (
      <div className={cn(gridClassNames, 'grid-cols-2')}>
        <MenuCategoriesLoader count={LOADER_CATEGORY_COUNT} />
      </div>
    )
  }

  if (debouncedCategorySearch) {
    return null
  }

  const isTwoColumn =
    filteredMenuCategories.length > SINGLE_COLUMN_THRESHOLD &&
    !showCategoryListViewOnLandingPage

  return (
    <div
      className={cn(gridClassNames, {
        'grid-cols-1': !isTwoColumn,
        'grid-cols-2': isTwoColumn,
      })}
      data-testid="venue-categories-container"
    >
      <MenuCategories
        venueSlug={venueSlug}
        orderingType={orderingType}
        orderingTypeSlug={orderingTypeSlug}
        categories={filteredMenuCategories}
        orderingWindowEnabled={orderingWindowEnabled}
        listViewEnabled={showCategoryListViewOnLandingPage}
        isTwoColumn={isTwoColumn}
      />
    </div>
  )
}
