import { TransformType } from '@mr-yum/cdn-image'
import { NotFound } from 'components/Common/NotFound'
import { Image } from 'components/Shared/Image'
import { VenueCategories } from 'components/VenueV2/VenueCategories'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { VenueLandingDocument } from 'gql/graphql'
import { useCartLanding } from 'hooks/useCart'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from 'urql'

import PersonalisedExperience from './PersonalisedExperience'
import { Sheets } from './Sheets/Sheets'
import { VenueHeaderInfo } from './VenueHeaderInfo'
import { VenueLogo } from './VenueLogo'

export type VenuePageProps = {
  orderingTypePrompt?: boolean
  showThePassUpdateRequiredNotice?: boolean
}

export const VenuePageV2 = ({ orderingTypePrompt = false }: VenuePageProps) => {
  const bannerRef = useRef<HTMLDivElement | null>(null)
  const [bannerHeight, setBannerHeight] = useState(112)
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const intl = useIntl()

  const [{ data: venueData, error: venueError, fetching: venueFetching }] =
    useQuery({
      query: VenueLandingDocument,
      variables: {
        venueSlug,
      },
    })

  const venue = venueData?.guestVenue

  const {
    cart,
    stale: cartStale,
    fetching: cartFetching,
    error: cartError,
  } = useCartLanding({
    venueSlug,
    orderingType,
    pause: !venue,
    requestPolicy: 'cache-and-network',
  })

  const mobileBanner = venue?.mobileBanner ?? venue?.banner
  const mvoEnabled = venue?.multiVendorEnabled

  useEffect(() => {
    if (bannerRef.current) {
      const updateHeight = () => {
        if (bannerRef?.current) {
          setBannerHeight(bannerRef.current.clientHeight)
        }
      }

      updateHeight()

      window.addEventListener('resize', updateHeight)
      return () => window.removeEventListener('resize', updateHeight)
    }
  }, [])

  if (venueError || (!venueFetching && !venue)) {
    return (
      <>
        <NotFound
          message={intl.formatMessage(
            {
              defaultMessage: 'Venue {venueSlug} not found',
              id: '2R567s',
            },
            { venueSlug },
          )}
          error={venueError}
        />
      </>
    )
  }

  if (!venue) {
    return null
  }
  return (
    <div className="min-h-screen bg-gradient-to-tl from-[#DBFC45] to-[#F8CEFF]">
      <div className="m-auto flex min-h-screen max-w-[500px] flex-col bg-surface shadow-xl">
        <div
          ref={bannerRef}
          className="fixed top-0 flex aspect-[2.86] w-full max-w-[500px] items-center justify-center bg-surface"
        >
          {mobileBanner && (
            <Image
              image={mobileBanner}
              transform={TransformType.MOBILE_BANNER}
              className="!inset-0"
              alt=""
              layout="fill"
              objectFit="cover"
              priority
            />
          )}

          <VenueLogo venueLogo={venue.logo} />
        </div>
        <div
          className="z-10 flex-grow pb-6"
          style={{ paddingTop: `${bannerHeight - 24}px` }}
        >
          <div className="flex flex-col space-y-4 divide-y rounded-t-3xl bg-surface pt-2 shadow-[0px_-10px_10px_-5px_rgba(0,0,0,0.10)]">
            <VenueHeaderInfo
              venue={venue}
              orderingTypePrompt={orderingTypePrompt}
            />
            <PersonalisedExperience />
            <div className="px-4 pt-4">
              <h3 className="mb-4 my-heading-sm">
                {mvoEnabled ? (
                  <FormattedMessage
                    defaultMessage="Explore our vendors"
                    id="B3OeDj"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Browse our menu"
                    id="wPwRR3"
                  />
                )}
              </h3>
              <VenueCategories
                multiVendorEnabled={venue.multiVendorEnabled}
                showCategoryListViewOnLandingPage={
                  venue.showCategoryListViewOnLandingPage
                }
                cart={cart}
                cartStale={cartStale}
                cartFetching={cartFetching}
                orderingTypes={venue.orderingTypes}
                debouncedCategorySearch=""
              />
            </div>
          </div>
        </div>
      </div>
      <Sheets
        venue={venue}
        cart={cart}
        cartFetching={cartFetching}
        cartStale={cartStale}
        cartError={cartError}
        orderingTypePrompt={orderingTypePrompt}
      />
    </div>
  )
}
