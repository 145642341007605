import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { OrderingType, VenueLandingQuery } from 'gql/graphql'
import { useCartLanding } from 'hooks/useCart'
import React, { useMemo } from 'react'

import { AddressButton } from './AddressButton'
import { OrderingTypeButton } from './OrderingTypeButton'
import { OrderingWindowButton } from './OrderingWindowButton'
import { Sheets } from './Sheets/Sheets'
import { TableNumberButton } from './TableNumberButton'
import { findSettingsByOrderingType } from './utils/venueUtils'

interface Props {
  venue: NonNullable<VenueLandingQuery['guestVenue']>
  orderingTypePrompt: boolean
}

export const TableAndDiningTypeSelection = ({
  venue,
  orderingTypePrompt,
}: Props) => {
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const settings = findSettingsByOrderingType({
    venue,
    orderingType,
  })

  const {
    cart,
    stale: cartStale,
    fetching: cartFetching,
    error: cartError,
  } = useCartLanding({
    venueSlug,
    orderingType,
    pause: !venue,
    requestPolicy: 'cache-and-network',
  })

  const tableIsOff = useMemo(() => {
    if (!cart) {
      return false
    }

    return cart.cartValidationErrorsV2.some(
      (value) => value.type === 'TABLE_NUMBER_ALL_OFF',
    )
  }, [cart])

  if (cartFetching) {
    return <div className="mt-2 h-[22px] w-[120px] rounded skeleton" />
  }

  const isVisualMenu = orderingType === OrderingType.Menu

  return (
    <>
      <div className="flex w-full items-center">
        <div className="flex flex-col items-start justify-between gap-1">
          <OrderingTypeButton venue={venue} />
          <AddressButton venue={venue} cart={cart} />
          {!isVisualMenu && !cartError && (
            <OrderingWindowButton
              venue={venue}
              venueSlug={venueSlug}
              tablePromptText={settings?.tablePromptText}
              orderingType={orderingType}
              cart={cart}
            />
          )}
        </div>
        <TableNumberButton
          orderingTypeSlug={orderingTypeSlug}
          orderingType={orderingType}
          venue={venue}
          venueSlug={venueSlug}
          isLoading={cartFetching}
          isTableTurnedOff={tableIsOff}
          cart={cart}
          tablePromptText={settings?.tablePromptText}
          tableShortText={settings?.tableShortText}
        />
      </div>
      <Sheets
        venue={venue}
        cart={cart}
        cartFetching={cartFetching}
        cartStale={cartStale}
        cartError={cartError}
        orderingTypePrompt={orderingTypePrompt}
      />
    </>
  )
}
