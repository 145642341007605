import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import { CartValidator } from 'components/Cart/validation'
import { Text } from 'components/Skeleton/Text'
import { TableNumberLabel } from 'components/TableNumber/TableNumberLabel'
import {
  useGetNextSheet,
  useSheetToggle,
} from 'components/Venue/utils/sheetUtils'
import {
  GuestVenue,
  OrderingType,
  VenueOrderingWindowsDocument,
} from 'gql/graphql'
import { OrderingTypeSlug } from 'lib/routes'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'urql'

import { OrderingTypeVenueValidator } from './utils/venueUtils'

interface Props {
  venueSlug: string
  venue: Pick<GuestVenue, 'skipTableNumberModal'> & OrderingTypeVenueValidator
  orderingTypeSlug: OrderingTypeSlug
  orderingType: OrderingType
  cart?: CartValidator | null
  tableShortText?: string
  tablePromptText?: string
  isLoading: boolean
  isTableTurnedOff?: boolean
}

export const TableNumberButton = ({
  orderingType,
  cart,
  tableShortText,
  isLoading,
  venueSlug,
  venue,
  isTableTurnedOff,
}: Props) => {
  const intl = useIntl()
  const sheetToggle = useSheetToggle()

  const [{ data: venueOrderingWindowsData }] = useQuery({
    query: VenueOrderingWindowsDocument,
    variables: { venueSlug, orderingType },
  })

  const asapSettings =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow
      ?.asapWindowEnabled

  const { nextSheet, addPaths, editPaths } = useGetNextSheet({
    cart,
    asapEnabled: asapSettings,
    venue,
  })

  const handleOnClick = useCallback(() => {
    void sheetToggle({ sheet: nextSheet })
  }, [nextSheet, sheetToggle])

  if (isLoading || !cart) {
    return (
      <div className="flex min-h-[58px] items-center px-4 py-3 md:pr-8 lg:pr-12">
        <Text chars={12} />
      </div>
    )
  }

  if (addPaths.length === 0 && editPaths.length === 0) {
    return null
  }

  const isWithTable =
    !isTableTurnedOff &&
    (orderingType === OrderingType.DineIn ||
      orderingType === OrderingType.InVenue)

  const tableNumber =
    cart?.tableNumber && !addPaths.includes('table-number')
      ? cart?.tableNumber
      : null

  return (
    <>
      {isWithTable && (
        <div className="flex flex-col md:w-auto">
          <div className="flex items-center gap-1">
            <span> {`\u2022`}</span>
            <div className="my-body-sm">
              <Button
                type="button"
                variant="link-primary"
                className="gap-0"
                size="sm"
                aria-label={intl.formatMessage({
                  defaultMessage: 'Configure ordering type',
                  id: 'R0MNO0',
                })}
                onClick={handleOnClick}
                rightIcon={
                  <ChevronDownIcon
                    width={20}
                    height={20}
                    className="flex-shrink-0"
                  />
                }
              >
                <TableNumberLabel
                  shortText={tableShortText}
                  number={tableNumber}
                  area={cart?.tableArea}
                />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
