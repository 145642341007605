import {
  cn,
  ModalContainer,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  useBreakpoint,
} from '@mr-yum/frontend-ui'
import React, { forwardRef, ReactNode } from 'react'

import { Sheet as LegacySheet } from './Common/Sheet'

interface Props {
  open: boolean
  children: ReactNode
  onOpenChange: () => void
  title?: React.ReactNode
  noBorderBottom?: boolean
}

export const ResponsiveSheet = forwardRef<HTMLDivElement, Props>(
  ({ open, children, onOpenChange, title, noBorderBottom }, ref) => {
    const breakpoint = useBreakpoint()
    const isDesktop = ['lg', 'xl'].includes(breakpoint)

    if (isDesktop) {
      return (
        <ModalContainer isOpen={open} onClose={onOpenChange}>
          <div
            ref={ref}
            className="w-[calc(100vw-32px)] max-w-[500px] overflow-hidden rounded"
          >
            <LegacySheet
              showBack={false}
              isCloseable
              title={title}
              onClose={onOpenChange}
            >
              {children}
            </LegacySheet>
          </div>
        </ModalContainer>
      )
    }

    return (
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent ref={ref} side="bottom" className="rounded-t-xl">
          <SheetHeader
            className={cn({ 'border-b': !noBorderBottom }, 'px-4 py-2')}
          >
            {title && (
              <SheetTitle className="grow pl-10 text-center">
                {title}
              </SheetTitle>
            )}
          </SheetHeader>
          {children}
        </SheetContent>
      </Sheet>
    )
  },
)

ResponsiveSheet.displayName = 'ResponsiveSheet'
