import { CurrentUserQuery } from 'gql/graphql'
import React from 'react'

interface Props {
  fetching: boolean
  currentUser: CurrentUserQuery['currentUser']
}

export const UserGreeting = ({ fetching, currentUser }: Props) => {
  const firstName = currentUser?.name?.split(' ')[0]

  if (fetching) {
    return <div className="mb-2 h-5 w-24 rounded skeleton" />
  }
  return (
    <span className="my-heading-sm">Hey {firstName && firstName + ' '}👋</span>
  )
}
